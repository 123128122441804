import axios from "axios";
import jwtDecode from "jwt-decode";

import { Auth } from "src/types/auth";

const auth_api = axios.create({
  baseURL: `${process.env.REACT_APP_AUTH_URL}`,
});

export const getGuestToken = async (payload: {
  clientId: string;
  token?: string;
}): Promise<Auth> => {
  const { clientId, token } = payload;

  const sessionKey = `__brix_session_token_${clientId}`;
  let accessToken = token ?? localStorage.getItem(sessionKey);

  if (accessToken) {
    try {
      const user: any = jwtDecode(accessToken as string);
      if (user.exp * 1000 - Date.now() < 0) {
        accessToken = null;
      }
    } catch (error) {
      accessToken = null;
    }
  }

  if (!accessToken) {
    const { data } = await auth_api.post("/auth/token/guest", {
      partner: clientId,
      locale: window.navigator.language,
    });
    accessToken = data.accessToken;
  }

  if (!accessToken) throw new Error("토큰이 발급을 실패했습니다..");

  const user: Auth = jwtDecode(accessToken);
  localStorage.setItem(sessionKey, accessToken);
  return { ...user, token: accessToken };
};
