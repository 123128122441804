import { v4 as uuidv4 } from "uuid";

import VideoPlayer from "./instance/player";

if (!window?.SoyEmbeddedPlayer) {
  window.SoyEmbeddedPlayer = VideoPlayer;
}

if (!window?.parseEmbeddedClasses) {
  window.parseEmbeddedClasses = () => {
    const embeddedElements = document.getElementsByClassName("embedded-player");

    Array.from(embeddedElements).forEach((embeddedElement) => {
      const elementId = uuidv4();
      embeddedElement.id = elementId;
      const id = embeddedElement.getAttribute("data-id");

      if (id && elementId) {
        new VideoPlayer({ elementId }).setup({
          id,
        });
      } else {
        console.error(
          `Not found element that has id(#${elementId}) or data-id=${id}.`
        );
      }
    });
  };
}
