import { useEffect /* , useRef */, useMemo, useRef, useState } from "react";
import {
  Kinesis,
  KinesisEnv,
  KinesisProvider,
} from "@squadx-developer/brix-library/kinesis";
import Hls from "hls.js";

import { PlayerComponentParams } from "../types/player";
import { useEventListener } from "../useEventListener";
import styles from "./VideoPlayer.module.scss";
import { getGuestToken } from "src/apis/auth";
const { base, play_button_wrapper, play_button, play_img } = styles;

export default function VideoPlayer({
  env,
  params: {
    id,
    autoplay,
    controls,
    loop,
    muted,
    reaction,
    objectFit,
    orgWidth,
    orgHeight,
    url,
    filePath,
  },
  videoData,
}: PlayerComponentParams) {
  const src =
    url && filePath ? url + filePath : videoData?.media.output.vod ?? "";

  const divRef = useRef<HTMLDivElement>(null);
  const [isReady, setIsReady] = useState(false);
  const [play, setPlay] = useState(false);

  const kinesis = useMemo(
    () =>
      new Kinesis({
        identityPoolId: KinesisEnv[env].identityPoolId,
        streamName: KinesisEnv[env].streamName,
      }),
    [env]
  );

  const handleOnClick = () => {
    const baseElement = divRef?.current;
    if (baseElement) {
      const videoElement = baseElement.querySelector("video");

      if (videoElement) {
        videoElement.play();
      }
    }
  };

  useEffect(() => {
    if (!isReady) return;
    const baseElement = divRef?.current;
    const rootElement = baseElement?.parentElement;

    if (rootElement) {
      if (!rootElement.dataset.id) {
        rootElement.dataset.id = id;
      }

      const r = document.querySelector(":root") as HTMLElement;
      if (r) {
        r.style.setProperty("--inject-modal-background-color", "#FFF");
      }
    }

    if (orgWidth && orgHeight) {
      if (rootElement) {
        // const widthStandard = rootElement.parentElement?.offsetWidth ?? 0;
        // const calcHeight = widthStandard * (orgHeight / orgWidth);
        // rootElement.style.height = `${calcHeight}px`;

        rootElement.style.height = `calc(100vw * ${orgHeight}/${orgWidth})`;
      }
    }

    if (baseElement) {
      baseElement.style.position = "relative";
      baseElement.style.width = "100%";
      baseElement.style.height = "100%";
    }

    const videoElement = document.createElement("video");
    videoElement.autoplay = autoplay;
    videoElement.controls = controls;
    videoElement.loop = loop;
    videoElement.muted = muted;
    videoElement.playsInline = true;
    videoElement.preload = "metadata";
    videoElement.style.display = "block";
    videoElement.style.width = "100%";
    videoElement.style.height = "100%";
    videoElement.style.objectFit = objectFit;
    videoElement.style.backgroundColor = "#000";

    if (videoData) {
      const { images, aspectRatioType } = videoData;
      videoElement.poster = images[0];
      videoElement.style.aspectRatio = aspectRatioType.replace(":", "/");
    }

    function handleMouseOver() {
      if (reaction) {
        videoElement.play();
      }
    }

    function handleMouseLeave() {
      if (reaction) {
        videoElement.pause();
      }
    }

    function handlePlay() {
      setPlay(true);
      kinesis.start();
    }

    function handlePause() {
      kinesis.stop();
    }

    function handleCanPlay() {
      baseElement?.appendChild(videoElement);
      if (videoElement) {
        if (autoplay && muted) {
          videoElement.play();
        } else {
          videoElement.pause();
        }
      }
    }

    let currentLevel;
    let firstLevel;
    let latency;
    let targetLatency;

    if (Hls.isSupported()) {
      const hls = new Hls({
        startLevel: 1,
        maxBufferLength: 3,
        maxBufferSize: 1 * 1000 * 1000,
        lowLatencyMode: true,
        enableWorker: true,
      });

      hls.loadSource(src);
      hls.attachMedia(videoElement);

      currentLevel = hls?.levels[hls.currentLevel]?.width || "auto";
      firstLevel = hls?.levels[hls.firstLevel]?.width || "auto";
      latency = hls?.latency?.toFixed(2);
      targetLatency = hls?.targetLatency;
    } else if (videoElement.canPlayType("application/vnd.apple.mpegurl")) {
      videoElement.src = src;

      currentLevel = "auto";
      firstLevel = "auto";
    } else {
      console.error("재생을 지원하지 않습니다.");
      return;
    }

    window?.__brix_console?.("hls", {
      isSupported: Hls.isSupported(),
      version: Hls.version,
      live: false,
      currentLevel,
      firstLevel,
      latency,
      targetLatency,
      durationTime: videoElement.duration,
      sync: "not live",
    });

    videoElement.addEventListener("loadedmetadata", handleCanPlay);
    videoElement.addEventListener("play", handlePlay);
    videoElement.addEventListener("pause", handlePause);
    videoElement.addEventListener("mouseover", handleMouseOver);
    videoElement.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      const videoElement = baseElement?.querySelector("video");

      if (videoElement) {
        videoElement.removeEventListener("loadedmetadata", handleCanPlay);
        videoElement.removeEventListener("play", handlePlay);
        videoElement.removeEventListener("pause", handlePause);
        videoElement.removeEventListener("mouseover", handleMouseOver);
        videoElement.removeEventListener("mouseleave", handleMouseLeave);
      }
    };
  }, [isReady, kinesis]);

  useEventListener("message", (e) => {
    const videoElement = divRef?.current?.querySelector("video");
    if (!videoElement) return;

    const { action } = e.data as {
      action?: "PLAY" | "REPLAY" | "PAUSE";
      id?: string;
    };

    if (typeof action === "undefined" || typeof e.data.id === "undefined") {
      return;
    }
    if (e.data.id !== id) return; //해당 영상은 아님

    if (action === "PLAY") videoElement.play();
    else if (action === "PAUSE") videoElement.pause();
    else if (action === "REPLAY") {
      videoElement.currentTime = 0;
      videoElement.play();
    } else console.warn("wrong action");
  });

  useEffect(() => {
    let clientId = videoData?.clientId;
    if (url && filePath) {
      clientId = url.split("/").at(-3);
    }

    if (id && clientId) {
      getGuestToken({ clientId })
        .then((res) => {
          kinesis.setClientId(clientId ?? "");
          kinesis.setType("library");
          kinesis.setId(id);
          kinesis.setUser(res.userId, res.role);
          kinesis.stop();
        })
        .catch((err) => {
          console.error("키네시스 요청 실패.\n", err);
        })
        .finally(() => {
          setIsReady(true);
        });
    } else {
      console.error(
        "id 또는 clientId가 존재하지 않습니다. id와 clientId가 없으면 통계수집이 불가능합니다."
      );
      setIsReady(true);
    }
  }, [id, videoData, url, filePath, kinesis]);

  return (
    <KinesisProvider kinesis={kinesis}>
      <div
        className={base}
        ref={divRef}
        data-src={src}
        // data-src={`/vod${src.split("/vod")[1]}`}
      >
        {!play && (
          <div className={play_button_wrapper} onClick={handleOnClick}>
            <div className={play_button}>
              <div className={play_img} id="play_img" />
            </div>
          </div>
        )}
      </div>
    </KinesisProvider>
  );
}

declare global {
  interface Window {
    __brix_console: (
      type: "kinesis" | "hls" | "normal" | "version" | "entryTime",
      param: any
    ) => void;
  }
}
