import axios from "axios";

import { Video } from "src/types/player";

export const getVod = async (id: string): Promise<Video> => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/public/vod/${id}`
  );
  return response.data;
};
