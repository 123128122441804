import { Root, createRoot } from "react-dom/client";

import { PlayerParams } from "../types/player";
import App from "./../App";

export default class VideoPlayer {
  private elementId: string;
  private root: Root;

  constructor({ elementId = "root" }: { elementId?: string }) {
    this.elementId = elementId;
    this.root = createRoot(document.getElementById(elementId) as HTMLElement);
  }

  setup(params?: PlayerParams) {
    if (this.root) {
      this.root.render(<App props={params} />);
    } else {
      console.error(`Not found element that has id(#${this.elementId}).`);
    }
  }

  destroy() {
    if (this.root) {
      this.root.unmount();
    }
  }

  // dispatch(callback: any) {
  //   callback();
  // }
}
