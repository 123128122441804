// import { Root, createRoot } from "react-dom/client";

// import { getPlayerParams } from "./utils/player";
// import { getVod } from "./apis/player";
// import App from "./App";
import VideoPlayer from "./instance/player";

if (!window?.SoyEmbeddedPlayer) {
  window.SoyEmbeddedPlayer = VideoPlayer;
}
