import { PlayerParams } from "../types/player";

/**
 * 쿼리스트링의 '0' | '1'의 값을 boolean으로 변환해주는 함수
 * @param {string | null} param param
 */
function setBoolean(param: string | null, initValue: boolean) {
  if (!param) return initValue;
  return param === "1";
}
function setNumber(param?: number | string | null, initValue?: number) {
  const numberParam = Number(param);
  if (!param || isNaN(numberParam)) return initValue;

  return numberParam;
}

/**
 * 쿼리스트링을 파라미터로 변환해주는 함수
 */
export function getPlayerParams(params?: Partial<PlayerParams>): PlayerParams {
  const query = new URLSearchParams(window.location.search);

  const id = params?.id ?? query.get("id") ?? "";
  const autoplay = params?.autoplay ?? setBoolean(query.get("autoplay"), false);
  const controls = params?.controls ?? setBoolean(query.get("controls"), true);
  const loop = params?.loop ?? setBoolean(query.get("loop"), false);
  const muted = params?.muted ?? setBoolean(query.get("muted"), true);
  const reaction = params?.reaction ?? setBoolean(query.get("reaction"), false);
  const objectFit =
    params?.objectFit ??
    (query.get("objectFit") as "fill" | "contain" | "cover") ??
    "contain";

  const orgWidth =
    setNumber(params?.orgWidth) ??
    setNumber(query.get("orgWidth")) ??
    undefined;
  const orgHeight =
    setNumber(params?.orgHeight) ??
    setNumber(query.get("orgHeight")) ??
    undefined;
  // const stretch = setBoolean(query.get("stretch"));
  // const ratio = query.get("ratio");

  return {
    ...params,
    id,
    autoplay,
    controls,
    loop,
    muted,
    reaction,
    objectFit,
    orgWidth,
    orgHeight,
    // stretch,
    // ratio: ratioValue ? Number(ratioValue) / 100 : 0,
  };
}
